const businesses = [
  {
    name: 'Rau Entsorgungs GmbH',
    img: 'assets/betriebe/business_logo_rau_entsorgung.jpg',
    url: 'https://rau-entsorgungszentrum.de',
  },
  {
    name: 'Green Planet GmbH',
    img: 'assets/betriebe/business_logo_rau_greenplanet.jpg',
    url: 'https://rau-entsorgungszentrum.de',
  },
  {
    name: 'Garten- und Landschaftsbau Weißmüller',
    img: 'assets/betriebe/business_logo_weissmueller_logo.jpg',
    url: 'https://weissmueller.de',
  },
  {
    name: 'Heinz Holfelder Sanitär-Technik e.K.',
    img: 'assets/betriebe/business_logo_holfelder.jpg',
    url:
      'https://www.google.com/search?ei=cvdFX6u1CaKIlwTn0qKgDQ&q=Heinz+Holfelder+Sanit%C3%A4r-Technik+e.K.',
  },
  {
    name: 'Heizung Seeberger | Bäder mit Pfiff',
    img: 'assets/betriebe/business_logo_baedermitpfiff.jpg',
    url: 'https://www.heizung-seeberger.de/baeder-mit-pfiff',
  },
  {
    name: 'SIEKLA GmbH',
    img: 'assets/betriebe/business_logo_siekla_gmbh.jpg',
    url: 'https://www.siekla.de/',
  },
  {
    name: 'Heizung Seeberger | Bäder mit Pfiff',
    img: 'assets/betriebe/business_logo_seeberger.jpg',
    url: 'https://www.heizung-seeberger.de/',
  },
  {
    name: 'Fliesen Trösken',
    img: 'assets/betriebe/business_logo_fliesentroesken.jpg',
    url: 'https://www.fliesen-troesken.de',
  },
  {
    name: 'Natursteinwerk Georg Mehlinger oHG',
    img: 'assets/betriebe/business_logo_mehlinger.jpg',
    url: 'https://www.mehlinger-natursteinwerk.de',
  },
  {
    name: 'Engler GmbH',
    img: 'assets/betriebe/business_logo_engler.JPG',
    url: 'https://www.engler-umzuege.de',
  },
  {
    name: 'Sandra B',
    img: 'assets/betriebe/business_logo_sandra-b.jpg',
    url: 'https://www.sandra-b.de',
  },
  {
    name: 'Andreas König + Söhne GmbH',
    img: 'assets/betriebe/business_logo_koenig.JPG',
    url: 'https://www.koenigzahnraeder.de/',
  },
  {
    name: 'Schmidt Haustechnik GmbH',
    img: 'assets/betriebe/business_logo_schmidt.jpg',
    url: 'http://www.schmidt-haustechnik.eu/',
  },
  {
    name: 'Sanitär und Heizungstechnik Günter Wagner',
    img: 'assets/betriebe/business_logo_wagner_sanitaer.jpg',
    url:
      'https://www.google.com/search?q=Sanit%C3%A4r+und+Heizungstechnik+G%C3%BCnter+Wagner',
  },
  {
    name: 'Haushaltsservice Nürnberg',
    img: 'assets/betriebe/business_logo_Haushaltsservice.png',
    url: 'https://haushaltsservice-nuernberg.de',
  },
  {
    name: 'Umzüge Strohmann',
    img: 'assets/betriebe/business_logo_strohmann.jpeg',
    url: 'https://www.strohmann.com/',
  },
  {
    name: 'Mauro OTS GmbH',
    img: 'assets/betriebe/business_logo_mots.png',
    url: 'https://www.mauro-ots.de/',
  },
  {
    name: 'Eivi GmbH - Automation',
    img: 'assets/betriebe/business_logo_eivi_gmbh.jpg',
    url: 'https://www.eivi-gmbh.de/',
  },
  {
    name: 'Autohaus Konrad Schmidt GmbH',
    img: 'assets/betriebe/business_logo_KonradSchmidtGmbH.PNG',
    url: 'https://konradschmidt.de/',
  },
  {
    name: 'Elektro Meyer GmbH & Co. KG',
    img: 'assets/betriebe/business_logo_elektro_meyer.jpg',
    url: 'http://elektro-meyer.com/',
  },
  {
    name: 'C. MÜLLER E.K.',
    img: 'assets/betriebe/business_logo_c_mueller.png',
    url: 'https://www.c-mueller.biz/',
  },
  {
    name: 'Link Gemüse',
    img: 'assets/betriebe/business_logo_link_gemuese.png',
    url: 'https://www.link-gemuese.de/',
  },
  {
    name: 'Leomax Engineering GmbH',
    img: 'assets/betriebe/business_logo_leomax.jpg',
    url: 'https://www.google.com/search?q=leomax+engineering+gmb',
  },
  {
    name: 'Zwingel Haustechnik',
    img: 'assets/betriebe/business_logo_zwingel.jpg',
    url: 'https://www.zwingel-haustechnik.de/',
  },
  {
    name: 'ZEMU Garten',
    img: 'assets/betriebe/business_logo_zemu.png',
    url: 'https://www.zemugarten.de/',
  },
  {
    name: 'Elektro Heisler GmbH',
    img: 'assets/betriebe/business_logo_heisler.jpg',
    url: 'https://www.heisler-elektro.de/',
  },
  {
    name: 'Elektrotechnik Drechsler',
    img: 'assets/betriebe/business_logo_drechsler.jpg',
    url: 'http://elektrotechnik-drechsler.de/',
  },
  {
    name: 'Konrad Städtler GmbH',
    img: 'assets/betriebe/business_logo_staedtler.jpg',
    url: 'https://www.konrad-staedtler.de/',
  },
  {
    name: 'Firma Leykauf e.K.',
    img: 'assets/betriebe/business_logo_leykauf.jpg',
    url: 'http://www.leykauf-nbg.de/',
  },
  {
    name: 'Form und Farbe Ehmann GmbH',
    img: 'assets/betriebe/business_logo_form_und_farbe.png',
    url: 'https://www.formundfarbe-ehmann.com/',
  },
  {
    name: 'Hahn Auf Straßen innovativ GmbH & Co. KG',
    img: 'assets/betriebe/business_logo_hahn.png',
    url: 'https://www.verkehrssicherung-hahn.de/',
  },
  {
    name: 'Dieter Lindner GmbH',
    img: 'assets/betriebe/business_logo_lindner.jpeg',
    url: 'https://www.lindnermetallbau.com/',
  },
  {
    name: 'Bösl Garten- und Landschaftsbau',
    img: 'assets/betriebe/business_logo_boesl.jpg',
    url: 'https://www.gartenbau-boesl.de/',
  },
  {
    name: 'Peter Ultsch Baugeschäft GmbH & Co.KG.',
    img: 'assets/betriebe/business_logo_peter_ultsch.png',
    url: 'https://www.peter-ultsch-bau.de/',
  },
];

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

class Business extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var items = [];

    var array = shuffle(businesses);
    for (var i = 0; i < array.length; i++) {
      items.push(
        <div className="col-md-4 col-12" key={i}>
          <a href={array[i].url} target="_blank">
            <div className="py-3 business-logo">
              <img src={array[i].img} alt={array[i].name} />
            </div>
          </a>
        </div>
      );
    }
    return <div className="row">{items}</div>;
  }
}

ReactDOM.render(<Business />, document.getElementById('businesses'));
